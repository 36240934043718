#CategoryPage {
  padding: 8.333333vw 4.166667vw;
}
#CategoryPage .wrap {
  margin: 0 auto;
  max-width: 70rem;
}
#CategoryPage h1 {
  font-size: 1.458333rem;
  line-height: 1.25;
  font-weight: 400;
  color: #ff4d2c;
  margin: 0 1em 0 0;
}
@media only screen and (min-width: 560px) {
  #CategoryPage h1 {
    font-size: 4.166666vw;
  }
}
@media only screen and (min-width: 720px) {
  #CategoryPage {
    padding: 3.75rem 1.875rem;
  }
  #CategoryPage h1 {
    font-size: 1.875rem;
  }
}

#CategoryPage h1,
#CategoryPage .controller-group-container,
#CategoryPage .controller-group {
  display: inline-block;
  vertical-align: middle;
}
#CategoryPage .controller-group-container.other-filters .controller-group {
  margin-right: 2.083333vw;
  padding-top: 0.555556vw;
}
#CategoryPage .controller-group-container.categories .controller-group {
  margin: 2.083333vw 0 4.166667vw;
  vertical-align: baseline;
}
#CategoryPage .controller-group-container.categories .controller-group.label {
  margin-bottom: 0;
}
@media only screen and (min-width: 720px) {
  #CategoryPage .controller-group-container.other-filters .controller-group {
    margin-right: 0.9375em;
    padding-top: 0.25em;
  }
  #CategoryPage .controller-group-container.categories .controller-group {
    margin: 0.9375em 0 1.875em;
  }
}
